import * as React from 'react'
import {
  Paper,
  Grid,
  FormControl,
  Button,
  MenuItem,
  InputLabel,
} from '@mui/material'
import PageTitle from '../../components/title/PageTitle'
import { useState } from 'react'
import AxiosClient from '../../utils/axios'
import { useNavigate, useParams } from 'react-router-dom'
import LoadingBar from '../../components/loading/LoadingBar'
import ErrorMessage from '../../utils/errorMessage'
import HttpErrorNotification from '../../components/notifications/HttpErrorNotification'
import Breadcrumb from '../../components/breadcrumb/Breadcrumb'
import Select from '@mui/material/Select'
import { ROLE_LABELS } from '../../utils/const'

export default function ChangeRole() {
  const navigate = useNavigate()
  const params = useParams()
  const [sendRequest, setSendRequest] = useState(false)
  const [alert, setAlert] = useState({ state: false, message: '' })
  const [role, setRole] = useState('dispatcher')

  const handleChange = (event) => {
    setRole(event.target.value)
  }

  const onSubmit = async () => {
    try {
      await AxiosClient.post(`/user/${params.userId}/role`, {
        role,
      })
      navigate(`/user/${params.userId}`)
    } catch (error) {
      const message = ErrorMessage(error)
      setAlert({
        state: true,
        message,
      })
      setSendRequest(false)
    }
  }
  return (
    <Paper sx={{ p: 1 }}>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <PageTitle title="Yangi foydalanuvchi huquqi" />
        </Grid>
        <Grid item xs={12}>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Huquq</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={role}
              label="Huquq"
              onChange={handleChange}
              defaultValue="dispatcher"
            >
              {Object.entries(ROLE_LABELS).map(([key, label]) => (
                <MenuItem key={key} value={key}>
                  {label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12}>
          <Button variant="contained" color="primary" onClick={onSubmit}>
            Saqlash
          </Button>
        </Grid>
        <Grid item xs={12}>
          {alert.state ? (
            <HttpErrorNotification message={alert.message} />
          ) : (
            <></>
          )}
        </Grid>

        {sendRequest ? <LoadingBar /> : <></>}

        <Breadcrumb />
      </Grid>
    </Paper>
  )
}
